import Vue from 'vue'
import Router from 'vue-router'
import home from '@/views/Home.vue'

Vue.use(Router)

const routes = [
    {
        path: '/new',
        name: 'Home',
        component:home,
    },
    {
        path: '/edit',
        name: 'Edit',
        component: () => import('@/views/Home'),
    },

    {
        path: '/view',
        name: 'View',
        component: () => import('@/views/View.vue'),
    },
]

export default new Router({
    mode: 'history',
    routes,
})
